/* eslint-disable no-undef */

import { useEffect } from 'react';
import Routing from './router';

// useEffect(() => {
//   console.log(process.env.CONFIG_BUILD_ID)
// }, [])

const App = () => {
  return (
    <div>
      <Routing />
    </div>
  );
};

export default App;
